import { ToastService, ToastType } from '@web-bankir/ui-kit/components/toast';
import { IError } from '@app/interfaces/error.interface';
import * as Sentry from '@sentry/angular-ivy';
import { Store } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';

class StateError extends Error {
  constructor(
    private action: string,
    private err: any,
  ) {
    super();

    this.name = this.action;
    this.message = this.err;
  }
}
export class StateErrorHandler {
  constructor(
    protected toast: ToastService,
    protected store: Store,
  ) {
  }

  protected catchError(errors: IError[], notify: boolean = true): void {
    if (!notify) {return;}

    for (const error of (errors || [])) {
      this.toast.notification({
        title: error.title || 'Ошибка',
        text: error.message || error.detail,
        type: ToastType.Error,
        timeout: error.timeout || 5000,
      });
    }
  }

  protected catchSentryError(actionName: string, error: any): void {
    if (typeof error === 'string') {
      this.executeSentryCaptureException(actionName, error)
    }
    else {
      for (const jsonError of (error.error?.errors || [])) {
        this.executeSentryCaptureException(actionName, jsonError.message || jsonError.detail, error)
      }
    }
  }

  private executeSentryCaptureException(actionName: string, errorMessage: any, error?: HttpErrorResponse) {
    if(error?.status >= 400 && error?.status <= 499) {
      return;
    }

    Sentry.captureException(new StateError(errorMessage, actionName), {
      tags: {
        NgXSAction: actionName,
        phone: this.store.selectSnapshot(state => state.profile?.form?.model?.phone),
        userId: this.store.selectSnapshot<number>(state => state.Auth?.userId),
        wbCrossId: this.store.selectSnapshot(state => state.Events?.webbankirId),
        'httpRequest.wbRequestId': error?.headers?.get('wb-request-id'),
        'httpRequest.url': error?.url,
        'httpRequest.status': error?.status,
        'httpRequest.error': error?.message,
      },
    });
  }

}
