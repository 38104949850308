import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { VirtualCardService } from '../services/virtual-card.service';
import { IVirtualCardState } from './virtual-card.state.interface';
import { VIRTUAL_CARD_STATE_DEFAULTS } from './virtual-card-state-defaults.const';
import { VirtualCardActions } from './virtual-card.actions';
import { StateErrorHandler } from '@app/helpers/abstractions/state-error-handler.class';
import { ToastService } from '@web-bankir/ui-kit/components/toast';
import { VirtualCardCreationStatus } from '../constants/virtual-card-creation-status.const';
import { RequestStatus } from '@app/constants/request-status.const';
import { operationsMap } from '../helpers/virtual-card-operations-map.helper';
import { IVirtualCardOperation } from '../interfaces/virtual-card-operation.interface';
import { YoomoneyWalletWidgetService } from '../services/yoomoney-wallet-widget.service'; 

@State<IVirtualCardState>({
  name: 'VirtualCard',
  defaults: VIRTUAL_CARD_STATE_DEFAULTS,
})
@Injectable()
export class VirtualCardState extends StateErrorHandler {
  constructor(
    private service: VirtualCardService,
    protected toast: ToastService,
    protected store: Store,
    private yoomoneyWalletWidgetService: YoomoneyWalletWidgetService
  ) {
    super(toast, store);
  }

  @Selector()
  public static canOpenCard(state: IVirtualCardState): boolean {
    return state.data?.status && state.data.status !== VirtualCardCreationStatus.Unavailable && !state.hideNewCard;
  }

  @Action(VirtualCardActions.LoadState)
  public load(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.LoadState) {
    ctx.setState(
      patch({
        status: RequestStatus.Pending,
      })
    );
    return this.service.check().pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.LoadStateSuccess(response.data));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.LoadStateFail', err);
        return ctx.dispatch(new VirtualCardActions.LoadStateFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.LoadStateSuccess)
  public loadStateSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.LoadStateSuccess) {
    ctx.setState(
      patch({
        data: action.payload.attributes,
        status: RequestStatus.Load,
      })
    );
  }

  @Action(VirtualCardActions.LoadStateFail)
  public loadStateFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.LoadStateFail) {
    ctx.setState(
      patch({
        data: null,
        status: RequestStatus.Error,
      })
    );
  }

  @Action(VirtualCardActions.Context)
  public context(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Context) {
    return this.service.getContext().pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.ContextSuccess(response.data));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.ContextFail', err);
        return ctx.dispatch(new VirtualCardActions.ContextFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.ContextSuccess)
  public contextSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ContextSuccess) {
  }

  @Action(VirtualCardActions.ContextFail)
  public contextFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ContextFail) {
    this.catchError(action.payload);
  }

  @Action(VirtualCardActions.Create)
  public create(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Create) {
    return this.service.create(action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.CreateSuccess(response.data));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.CreateFail', err);
        return ctx.dispatch(new VirtualCardActions.CreateFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.CreateSuccess)
  public createSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.CreateSuccess) {
  }

  @Action(VirtualCardActions.CreateFail)
  public createFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.CreateFail) {
    this.catchError(action.payload);
  }

  @Action(VirtualCardActions.HideNewCard)
  public hideNewCard(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.HideNewCard) {
    ctx.setState(
      patch({
        hideNewCard: true
      })
    );
  }

  @Action(VirtualCardActions.Token)
  public token(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Token) {
    return this.service.token().pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.TokenSuccess(response.data.attributes));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.TokenFail', err);
        return ctx.dispatch(new VirtualCardActions.TokenFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.TokenSuccess)
  public tokenSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TokenSuccess) {
  }

  @Action(VirtualCardActions.TokenFail)
  public tokenFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TokenFail) {
    this.catchError(action.payload);
  }

  @Action(VirtualCardActions.AuthCode)
  public authCode(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.AuthCode) {
    return this.yoomoneyWalletWidgetService.getAuthCode(action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.AuthCodeSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.AuthCodeFail', err);
        return ctx.dispatch(new VirtualCardActions.AuthCodeFail(err));
      })
    );
  }

  @Action(VirtualCardActions.AuthCodeSuccess)
  public authCodeSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.AuthCodeSuccess) {
  }

  @Action(VirtualCardActions.AuthCodeFail)
  public authCodeFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.AuthCodeFail) {
    this.catchError(action.payload);
  }

  @Action(VirtualCardActions.Block)
  public block(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Block) {
    const id = ctx.getState().data.account.cardId;
    return this.service.block(id).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.BlockSuccess());
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.BlockFail', err);
        return ctx.dispatch(new VirtualCardActions.BlockFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.BlockSuccess)
  public blockSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.BlockSuccess) {
  }

  @Action(VirtualCardActions.BlockFail)
  public blockFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.BlockFail) {
  }

  @Action(VirtualCardActions.Reissue)
  public reissue(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Reissue) { 
    return this.service.reissue({
      type: "cards",
      attributes: {
        accountNumber: ctx.getState().data.account.accountNumber
      }
    }).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.ReissueSuccess(response.data));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.ReissueFail', err);
        return ctx.dispatch(new VirtualCardActions.ReissueFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.ReissueSuccess)
  public reissueSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ReissueSuccess) {
  }

  @Action(VirtualCardActions.ReissueFail)
  public reissueFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ReissueFail) {
  }

  @Action(VirtualCardActions.ReissueStatus)
  public reissueStatus(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ReissueStatus) {
    return this.service.reissueStatus(action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.ReissueStatusSuccess(response.data));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.ReissueStatusFail', err);
        return ctx.dispatch(new VirtualCardActions.ReissueStatusFail(err.error?.errors));
      })
    );
  }

  @Action(VirtualCardActions.ReissueStatusSuccess)
  public reissueStatusSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ReissueStatusSuccess) {
  }

  @Action(VirtualCardActions.ReissueStatusFail)
  public reissueStatusFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ReissueStatusFail) {
  }

  @Action(VirtualCardActions.Operations)
  public operations(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Operations) {
    ctx.setState(
      patch({
        operations: null
      })
    );
    const accountNumber = ctx.getState().data.account.accountNumber;
    
    return this.loadOperations(accountNumber, action.from, action.to).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.OperationsSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.OperationsFail', err);
        return ctx.dispatch(new VirtualCardActions.OperationsFail(err.error?.errors));
      })
    )
  }

  private loadOperations = (acc: string, from: string, to: string, cursor: string = null): Observable<IVirtualCardOperation[]> => {
    return this.service.operations(acc, from, to, cursor).pipe(
      switchMap((response: any) => {
        if (response.meta?.cursor) {
          return this.loadOperations(acc, from, to, response.meta.cursor).pipe(
            map(nextItems => [...response.data, ...nextItems])
          );
        }
        return of(response.data);
      }),
      catchError((err, caught) => err),
    );
  }

  @Action(VirtualCardActions.OperationsSuccess)
  public operationsSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.OperationsSuccess) {
    ctx.setState(
      patch({
        operations: operationsMap(action.payload),
      })
    );
  }

  @Action(VirtualCardActions.OperationsFail)
  public operationsFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.OperationsFail) {
  }

  @Action(VirtualCardActions.CardData)
  public cardData(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.CardData) {
    return this.yoomoneyWalletWidgetService.displayCardData(action.widgetToken, action.cardId).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.CardDataSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.CardDataFail', err);
        return ctx.dispatch(new VirtualCardActions.CardDataFail(err));
      })
    );
  }

  @Action(VirtualCardActions.CardDataSuccess)
  public cardDataSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.CardDataSuccess) {
  }

  @Action(VirtualCardActions.CardDataFail)
  public cardDataFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.CardDataFail) {
  }

  @Action(VirtualCardActions.ChangePin)
  public changePin(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ChangePin) {
    return this.yoomoneyWalletWidgetService.changePin(action.widgetToken, action.cardId).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.ChangePinSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.ChangePinFail', err);
        return ctx.dispatch(new VirtualCardActions.ChangePinFail(err));
      })
    );
  }

  @Action(VirtualCardActions.ChangePinSuccess)
  public changePinSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ChangePinSuccess) {
  }

  @Action(VirtualCardActions.ChangePinFail)
  public changePinFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.ChangePinFail) {
  }

  @Action(VirtualCardActions.TopUp)
  public topUp(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TopUp) {
    return this.yoomoneyWalletWidgetService.topUp(action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.TopUpSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.TopUpFail', err);
        return ctx.dispatch(new VirtualCardActions.TopUpFail(err));
      })
    );
  }

  @Action(VirtualCardActions.TopUpSuccess)
  public topUpSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TopUpSuccess) {
  }

  @Action(VirtualCardActions.TopUpFail)
  public topUpFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TopUpFail) {
  }

  @Action(VirtualCardActions.Transfer)
  public transfer(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.Transfer) {
    return this.yoomoneyWalletWidgetService.transfer(action.payload).pipe(
      tap((response) => {
        ctx.dispatch(new VirtualCardActions.TransferSuccess(response));
      }),
      catchError((err, caught) => {
        this.catchSentryError('VirtualCardActions.TransferFail', err);
        return ctx.dispatch(new VirtualCardActions.TransferFail(err));
      })
    );
  }

  @Action(VirtualCardActions.TransferSuccess)
  public transferSuccess(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TransferSuccess) {
  }

  @Action(VirtualCardActions.TransferFail)
  public transferFail(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.TransferFail) {
  }

  @Action(VirtualCardActions.InitWidget)
  public initWidget(ctx: StateContext<IVirtualCardState>, action: VirtualCardActions.InitWidget) {
    this.yoomoneyWalletWidgetService.init();
  }

  @Action(VirtualCardActions.Reset)
  public reset(ctx: StateContext<IVirtualCardState>) {
    ctx.setState(VIRTUAL_CARD_STATE_DEFAULTS);
  }
}
