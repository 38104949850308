import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  name: 'production',
  production: true,
  api: 'https://ng-api.webbankir.com/',
  rpcApi: 'https://lk.gate.webbankir.com/rpc',
  oldLk: 'https://webbankir.com/',
  gate: 'https://lk.gate.webbankir.com/',
  endpointId: 'Webbankir',
  release: 'lk-ng@WEBDEV-8096-1',
  gtmCode: 'GTM-N7MGBK2',
  yandexMetrikaId: 17582872,
  redirectToOldLK: false,
  metrics: {
    apiUrl: 'https://lk.gate.webbankir.com/rpc'
  },
  juicyScoreUrl: 'https://score.juicyscore.com',
  growthBook: {
    apiHost: 'https://growthbook-proxy.webbankir.team',
    clientKey: 'sdk-nQuDsOjSTIbvJt4B'
  },
  yoomoneyWidgetData: {},
};
