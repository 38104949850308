export enum EventsActionList {
  NEW_SESSION = '[Events] Generate session id',

  NEW_USER = '[Events] Generate user id',
  METRIC = '[Events] metric event',// TODO deprecated

  FORWARD_LK = '[Events] forwark to lk metric',

  FIELD_EVENT = '[Events] field event',

  EVENT = '[Events] new event ',

  GA_EVENT = '[Events] ga event',
  MINDBOX = '[Events] mindbox',
  ADD_METRIC = '[Events] add metric',
  REGISTRATION_STEP_ONE_COMPLETE = '[Events] registration step one complete',
  REGISTRATION_STEP_CONFIRMATION_COMPLETE = '[Events] registration step confirmation complete',
  PHONE_CONFIRMED = '[Events] phone confirmed',
  REGISTRATION_STEP_TWO_COMPLETE = '[Events] registration step two complete',
  REGISTRATION_STEP_THREE_COMPLETE = '[Events] registration step three complete',
  REGISTRATION_STEP_FOUR_COMPLETE = '[Events] registration step four complete',
  SET_WEBBANKIR_CROSS_ID = '[Events] Set webbankir cross id',
  REGISTRATION_SNG_NATIONALITY_COMPLETE = '[Events] registration sng nationality complete',

  REGISTER_YANDEX_USER_ID = '[Events] register yandex metric user id',

  EVENT_V2 = '[Events] new event v2',
  RPC_EVENT = '[Events] new rpc event',
}
