export enum EventName {
  Esia = 'esia_registration_start',
  InnFound = 'inn_found_and_placed',
  InnNotFound = 'inn_not_found',
  PhoneChanged = 'phone_changed',
  PhoneLinked = 'phone_linked',
  CardType = 'card_number_type',
  CardLinked = 'card_linked',
  Registration = 'registration_step',
  MobileID = 'personal_data_set',
  FrontendLkApiRestRequests = 'frontend_lk_api_rest_requests',
  CancelingBareBasket = 'canceling_bare_basket',
  ForwardToLK = 'forward_to_lk',
  IncomeModalWindowClosed = 'income_modal_window_closed',
  ActiveLoanPageOpened = 'ActiveLoanPageOpened',
  LoanDocumentsRequested = 'LoanDocumentsRequested',
  SmsCodeEntered = 'SmsCodeEntered',
  RefinancingInterestPaymentInitiated = 'RefinancingInterestPaymentInitiated',
  RefinancingModalSuccess = 'RefinancingModalSuccess',
  ExtRefinancingPDLMainV = 'ExtRefinancingPDL_Main_V',
  ExtRefinancingPDLMainT = 'ExtRefinancingPDL_Main_T',
  ExtRefinancingPDLCalcPercentV = 'ExtRefinancingPDL_Calcpercent_V',
  ExtRefinancingPDLCalcV = 'ExtRefinancingPDL_Calc_V',
  ExtRefinancingPDLCalcT = 'ExtRefinancingPDL_Calc_T',
  ExtRefinancingPDLCalcNoT = 'ExtRefinancingPDL_Calc_No_T',
  ExtRefinancingPDLMemoV = 'ExtRefinancingPDL_Memo_V',
  ExtRefinancingPDLMemoT = 'ExtRefinancingPDL_Memo_T',
  ExtRefinancingPDLMemoOkT = 'ExtRefinancingPDL_Memo_Ok_T',
  ExtRefinancingPDLMemoNoT = 'ExtRefinancingPDL_Memo_No_T',
  ExtRefinancingPDLPercentPayV = 'ExtRefinancingPDL_Percentpay_V',
  ExtRefinancingPDLPercentPayT = 'ExtRefinancingPDL_Percentpay_T',
  ExtRefinancingPDLInfoRepayV = 'ExtRefinancingPDL_Info_Repay_V',
  ExtRefinancingPDLInfoRepayT = 'ExtRefinancingPDL_Info_Repay_T',
  RefinancingPDLPercentPayT = 'RefinancingPDL_Percentpay_T',
  RefinancingPDLInfoRepayV = 'RefinancingPDL_Info_Repay_V',
  RefinancingPDLInfoRepayT = 'RefinancingPDL_Info_Repay_T',
  RefinancingPDLPercentPayV = 'RefinancingPDL_Percentpay_V',
  RegistrationStepCompleted = 'registration_step_completed',
  ProfileDelete = 'Profile_Delete',
}
