import { Inject, Injectable } from '@angular/core';
import { AccountType } from '@app/constants/account-type.const';
import { ICreateLoan } from '@app/states/product/interfaces/create-loan.interface';
import { ProductState } from '@app/states/product/states/product.state';
import { IProfileForm } from '@app/states/profile/interfaces/profile-form.interface';
import { ProfileState } from '@app/states/profile/states/profile.state';
import { Store } from '@ngxs/store';
import { DeviceInfoService } from '../device-info/device-info.service';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  constructor(
    @Inject('Window') private window: Window,
    @Inject('URL') private url: typeof URL,
    private deviceInfoService: DeviceInfoService,
    private store: Store
  ) {}

  public fillData(content: string): string {
    if (!content) {
      return;
    }

    const profile = this.store.selectSnapshot(ProfileState.form);
    const product = this.store.selectSnapshot(ProductState.form);

    return content
      .replace('{fullname}', profile?.fio || '')
      .replace('{lastName}', profile?.fio?.split(' ')[0] || '')
      .replace('{firstName}', profile?.fio?.split(' ')[1] || '')
      .replace('{middleName}', profile?.fio?.split(' ')[2] || '')
      .replace('{phone}', profile?.phone ? '+7' + profile?.phone : '')
      .replace('{birthDate}', profile?.bDay || '')
      .replace('{birthPlace}', profile?.bPlace || '')
      .replace(
        '{passport}',
        (profile?.passport || '') +
          ' выдан ' +
          (profile?.passportDateOfIssue || '') +
          ' код подразделения ' +
          (profile?.passportDivisionCode
            ? profile?.passportDivisionCode?.substring(0, 3) + '-' + profile?.passportDivisionCode?.substring(3)
            : '')
      )
      .replace('{passportSeries}', profile?.passport?.substring(0, 4) || '')
      .replace('{passportNumber}', profile?.passport?.substring(4) || '')
      .replace('{passportIssuedAt}', profile?.passportDateOfIssue || '')
      .replace(
        '{passportDivisionCode}',
        profile?.passportDivisionCode
          ? profile?.passportDivisionCode.substring(0, 3) + '-' + profile?.passportDivisionCode?.substring(3)
          : ''
      )
      .replace('{address}', profile?.address?.label || '')
      .replace('{identification}', `${profile?.inn ? 'ИНН: ' + profile?.inn : profile?.snilsOrInn?.length === 11 ? 'СНИЛС: ' + profile?.snilsOrInn : 'ИНН: ' + profile?.snilsOrInn}`)
      .replace('{paymentMethod}', this.paymentMethod(product) || '')
      .replace('{paymentNumber}', this.paymentNumber(product, profile))
      .replace('{security}', this.fillSecurity(product))
      .replace('{securityAnswer}', this.fillSecurityAnswer(product))
      .replace('{passportIssuedBy}', profile?.passportIssuedBy)
      .replace('{additionalIncome}', `${profile?.workAdditionalIncome}`);
  }

  public download(content: Blob, name: string = 'consent'): void {
    this.createLink(content, name);
  }

  public open(content: Blob, name: string = 'consent'): void {
    switch (content?.type) {
      case 'application/zip':
        this.createLink(content, name);
        return;
      case 'application/pdf':
        const pdfWindow = this.window.open(this.url.createObjectURL(content), '_blank', 'width=1200');

        if (!pdfWindow) {
          if (this.deviceInfoService.browserName === 'safari' && this.deviceInfoService.osName === 'Mac OS') {
            this.createLink(content, null);
          } else {
            this.createLink(content, name);
          }
        }
        return;
      default:
        const htmlWindow = this.window.open('about:blank', '_blank', 'width=1200');

        if (!htmlWindow) {
          const blob = new Blob([content], { type: 'text/html' });
          this.createLink(blob, name);
          return;
        }

        htmlWindow.document.write(content.toString());
        return;
    }
  }

  private createLink(content: Blob, name: string | null): void {
    const a = this.window?.document.createElement('a');

    if (name) {
      let filename = name;

      switch (content?.type) {
        case 'application/pdf':
          filename += '.pdf';
          break;
        case 'application/zip':
          filename += '.zip';
          break;
        default:
          break;
      }

      a.setAttribute('download', filename);
    }

    a.setAttribute('href', this.url.createObjectURL(content));
    a.setAttribute('target', '_blank');

    a.click();

    this.url.revokeObjectURL(a.href);
  }

  private paymentMethod(form: ICreateLoan): string {
    switch (form?.method?.value) {
      case AccountType.Contact:
        return `Наличными по системе денежных переводов CONTACT`;
      case AccountType.QIWI:
        return `QIWI кошелёк`;
      case AccountType.VirtualCard:
        return `ЮMoney кошелёк (Карта WEBBANKIR)`;
      default:
        return form?.method?.label || '';
    }
  }

  private paymentNumber(form: ICreateLoan, profile: IProfileForm): string {
    switch (form?.method?.value) {
      case AccountType.SBP:
        return '+7' + profile?.phone;
      case AccountType.Contact:
        return 'Наличными';
      case AccountType.VirtualCard:
        return '№' + (this.getCardNumber(form) || ' **********');
      default:
        return this.getCardNumber(form) || '';
    }
  }

  private fillSecurity(form: ICreateLoan): string {
    switch (form?.method?.value) {
      case AccountType.SBP:
        return 'Номер телефона зарегистрирован на моё имя';
      case AccountType.Card:
        return 'Банковская карта оформленная на моё имя и подтверждена';
      case AccountType.Contact:
        return 'Предъявление паспорта при получении денежных средств в пункте выдачи системы денежных переводов CONTACT';
      case AccountType.YooMoney:
        return 'ЮMoney кошелёк идентифицирован и зарегистрирован на моё имя';
      case AccountType.VirtualCard:
        return 'Кошелёк ЮMoney (Карта WEBBANKIR) идентифицирован и зарегистрирован на моё имя'
      case AccountType.QIWI:
        return 'QIWI кошелёк зарегистрирован на моё имя';
      default:
        return '';
    }
  }

  private fillSecurityAnswer(form: ICreateLoan): string {
    switch (form?.method?.value) {
      case AccountType.SBP:
        return 'Да';
      case AccountType.Card:
        return 'Да, введён одноразовый пароль от банка';
      case AccountType.VirtualCard:
      case AccountType.Contact:
      case AccountType.YooMoney:
      case AccountType.QIWI:
        return 'Да';
      default:
        return '';
    }
  }

  private getCardNumber(form: ICreateLoan): string {
    const card = form?.card;
    if (form?.method?.value === AccountType.Card) {
      return form.account?.id
        ? form?.account?.description
        : [
            card?.substring(0, 3),
            ...new Array(Math.max(0, (card?.length || 0) - 7)).fill('*'),
            card?.substring(card?.length - 4),
          ].join('');
    }
    // Для Юмани не нужно скрывать номер
    return card || form?.account?.description;
  }
}
